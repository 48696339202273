.stepper::-webkit-outer-spin-button,
.stepper::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.stepper {
    -moz-appearance: textfield;
    border-radius: 4px;
    padding: 0 16px;
    font-size: 13px;
    line-height: 1;
    height: 35px;
    outline: none;
    text-align: center;
    width: 100%;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
    -webkit-box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
    -moz-appearance: textfield;
    width: 111px;
}

.button {
    all: unset;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    z-index: 0;
    padding: 0;
    margin: 0;
    display: grid;
    place-items: center;
    border: none;
    border: 8px;
}

.button.disabled {
    color: var(--color-gray-light);
}

.minus {
    top: calc(50% - 12px);
    left: 8px;
}

.plus {
    top: calc(50% - 12px);
    right: 8px;
}

.wrapper {
    display: flex;
    position: relative;
}