export function getCalendarPrices() {
    return fetch(`${process.env.REACT_APP_BOOKING_API}/calendar/prices/get`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                return data.prices
            } else {
                throw new Error("Ошибка при загрузке цен.")
            }
        })
        .catch(() => {
            throw new Error("Ошибка при загрузке цен.")
        });
}