.button {
    all: unset;
    box-sizing: border-box;
    padding: 0 15px;
    font-size: 13px;
    background: var(--color-gray);
    height: 35px;
    margin-left: auto;
    cursor: pointer;
    border-radius: 4px;
    color: var(--color-white);
    transition: color .2s ease, background .2s ease;
    display: flex;
    align-items: center;
    gap: 4px;
}

.button:hover {
    background: var(--color-lilac-dark);
}

.gray {
    background: var(--color-gray-light);
    color: var(--color-gray);
}

.gray:hover {
    background: var(--color-white);
    color: black;
}

.dark {
    background: var(--color-gray);
    color: var(--color-gray-light);
}

.dark:hover {
    background: var(--color-gray);
    color: var(--color-white);
}

.medium {
    padding: 12px 18px;
    height: auto;
    font-size: 15px;
}