export function getRoomsLimits() {
    return fetch(`${process.env.REACT_APP_BOOKING_API}/booking/settings/limits`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                return data
            } else {
                throw new Error("Ошибка при загрузке модуля бронирования.")
            }
        })
        .catch(() => {
            throw new Error("Ошибка при загрузке модуля бронирования.")
        });
}


