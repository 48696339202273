import { useEffect, useState } from "react";

export default function useScreenSize() {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        function resize() {
            setSize({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }

        window.addEventListener("resize", resize);

        return () => window.removeEventListener("resize", resize)
    }, []);

    return size;
}