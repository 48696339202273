export const childrenOptions = [
    {
        value: '0',
        text: "До 1 года"
    },
    {
        value: '1',
        text: "1 год"
    },
    {
        value: '2',
        text: "2 года"
    },
    {
        value: '3',
        text: "3 года"
    },
    {
        value: '4',
        text: "4 года"
    },
    {
        value: '5',
        text: "5 лет"
    },
    {
        value: '6',
        text: "6 лет"
    },
    {
        value: '7',
        text: "7 лет"
    },
    {
        value: '8',
        text: "8 лет"
    },
    {
        value: '9',
        text: "9 лет"
    },
    {
        value: '10',
        text: "10 лет"
    },
    {
        value: '11',
        text: "11 лет"
    },
    {
        value: '12',
        text: "12 лет"
    },
    {
        value: '13',
        text: "13 лет"
    },
    {
        value: '14',
        text: "14 лет"
    },
    {
        value: '15',
        text: "15 лет"
    },
    {
        value: '16',
        text: "16 лет"
    },
    {
        value: '17',
        text: "17 лет"
    },

]