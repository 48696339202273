export function NotAvailableIcon() {
    return (
        <svg width="15" height="15" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 18.9823L39.5369 9.82172C40.4368 9.58305 41.0089 10.7014 40.2471 11.2099L13.1697 29.283C12.4045 29.7937 12.9852 30.9163 13.8868 30.6693L38.2122 24.0044C39.0912 23.7636 39.6812 24.8351 38.9626 25.3673L21.6491 38.1909C20.9336 38.7209 21.5152 39.7884 22.3926 39.5557L37.5 35.5469" stroke="currentColor" strokeWidth="5" strokeLinecap="round" />
        </svg>
    )
}

export function SpinnerIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 50 50" fill="none">
            <path d="M25 8.75001C20.6902 8.75001 16.557 10.4621 13.5095 13.5095C10.462 16.557 8.75 20.6902 8.75 25C8.75 25.4973 8.55246 25.9742 8.20083 26.3258C7.84919 26.6775 7.37228 26.875 6.875 26.875C6.37772 26.875 5.90081 26.6775 5.54917 26.3258C5.19754 25.9742 5 25.4973 5 25C5 21.0444 6.17298 17.1776 8.37061 13.8886C10.5682 10.5996 13.6918 8.03617 17.3463 6.52242C21.0009 5.00867 25.0222 4.6126 28.9018 5.3843C32.7814 6.15601 36.3451 8.06082 39.1421 10.8579C41.9392 13.6549 43.844 17.2186 44.6157 21.0982C45.3874 24.9778 44.9913 28.9992 43.4776 32.6537C41.9638 36.3082 39.4004 39.4318 36.1114 41.6294C32.8224 43.827 28.9556 45 25 45C24.5027 45 24.0258 44.8025 23.6742 44.4508C23.3225 44.0992 23.125 43.6223 23.125 43.125C23.125 42.6277 23.3225 42.1508 23.6742 41.7992C24.0258 41.4476 24.5027 41.25 25 41.25C29.3098 41.25 33.443 39.538 36.4905 36.4905C39.538 33.443 41.25 29.3098 41.25 25C41.25 20.6902 39.538 16.557 36.4905 13.5095C33.443 10.4621 29.3098 8.75001 25 8.75001Z" fill="currentColor" />
        </svg>
    )
}

export function PeopleIcon() {
    return (
        <svg width="18" height="33" viewBox="0 0 18 33" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame">
                <path id="Vector" d="M8.99981 8.05088C10.6155 8.05088 11.9253 6.74112 11.9253 5.12544C11.9253 3.50976 10.6155 2.2 8.99981 2.2C7.38413 2.2 6.07437 3.50976 6.07437 5.12544C6.07437 6.74112 7.38413 8.05088 8.99981 8.05088Z" fill="currentColor" />
                <path id="Vector_2" d="M9.00029 26.3374C9.00029 27.1447 8.34525 27.7998 7.53661 27.7998C6.72925 27.7998 6.07421 27.1447 6.07421 26.3374V13.1713V17.5598C6.07421 18.3678 5.41917 19.0228 4.61181 19.0228C3.80349 19.0228 3.14813 18.3678 3.14813 17.5598V11.7079C3.14813 10.0926 4.45853 8.7825 6.07421 8.7825C6.47133 9.1793 6.92797 9.4769 7.41661 9.67882C7.92189 9.88778 8.46109 9.99402 9.00029 9.99466C9.53853 9.99402 10.0771 9.8881 10.5827 9.67882C11.072 9.4769 11.5293 9.17898 11.9251 8.7825C13.5424 8.7825 14.8515 10.0923 14.8515 11.7079V17.5598C14.8515 18.3678 14.1961 19.0228 13.3894 19.0228C12.5808 19.0228 11.9254 18.3678 11.9254 17.5598V13.1713V26.3374C11.9254 27.1447 11.2717 27.7998 10.463 27.7998C9.65565 27.7998 9.00029 27.1447 9.00029 26.3374Z" fill="currentColor" />
            </g>
        </svg>
    )
}

export function SmallPeopleIcon() {
    return (
        <svg width="14" height="33" viewBox="0 0 14 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame">
                <path id="Vector" d="M7 13C8.10457 13 9 12.1046 9 11C9 9.89543 8.10457 9 7 9C5.89543 9 5 9.89543 5 11C5 12.1046 5.89543 13 7 13Z" fill="currentColor" />
                <path id="Vector_2" d="M7.00033 26.0003C7.00033 26.5522 6.55257 27 5.99981 27C5.44793 27 5.00016 26.5522 5.00016 26.0003V17.0001V20.0001C5.00016 20.5524 4.5524 21.0002 4.00052 21.0002C3.44798 21.0002 3 20.5524 3 20.0001V15.9998C3 14.8956 3.89574 14 5.00016 14C5.27162 14.2712 5.58376 14.4747 5.91778 14.6127C6.26317 14.7556 6.63175 14.8282 7.00033 14.8286C7.36825 14.8282 7.73639 14.7558 8.082 14.6127C8.41645 14.4747 8.72904 14.271 8.99962 14C10.1051 14 11 14.8953 11 15.9998V20.0001C11 20.5524 10.552 21.0002 10.0006 21.0002C9.44782 21.0002 8.99984 20.5524 8.99984 20.0001V17.0001V26.0003C8.99984 26.5522 8.55295 27 8.00019 27C7.44831 27 7.00033 26.5522 7.00033 26.0003Z" fill="currentColor" />
            </g>
        </svg>
    )
}

export function PhoneIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipath="url(#clip0_1_2)">
                <path d="M1.13622 6.58368C2.14185 10.5072 4.1835 14.0884 7.04755 16.9524C9.9116 19.8165 13.4928 21.8582 17.4163 22.8638C20.4352 23.6322 23 21.0364 23 17.9202V16.5102C23 15.7319 22.3669 15.1073 21.5928 15.0297C20.3089 14.9032 19.0487 14.6 17.8478 14.1287L15.7046 16.2719C12.2149 14.5992 9.40084 11.7851 7.72807 8.29544L9.8713 6.15221C9.39956 4.95136 9.09589 3.69112 8.96889 2.4072C8.89275 1.63169 8.26811 1 7.48978 1H6.07976C2.96361 1 0.367763 3.56483 1.13622 6.58368Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1_2">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                </clipPath>
            </defs>
        </svg>

    )
}