/**
 * Выбор окончания для слова 'ночь' / Им. падеж
 * 
 * @param mixed count - количество ночей
 * 
 * @return string
 */
export function localeNight(count) {
    // 2 последних символа
    const x = count.toString().slice(-2);
    // 1 последний символ
    const y = count.toString().slice(-1);

    if (["11", "12", "13", "14", "15", "16", "17", "18", "19"].includes(x)) {
        return "ночей";
    } else if (["1"].includes(y)) {
        return "ночь";
    }
    else if (["2", "3", "4"].includes(y)) {
        return "ночи";
    } else {
        return "ночей";
    }
}

/**
 * Выбор окончания слова 'гость' / Им. падеж
 * 
 * @param mixed count - кол-во гостей
 * 
 * @return [type]
 */
export function localeGuest(count) {
    // 2 последних символа
    const x = count.toString().slice(-2);
    // 1 последний символ
    const y = count.toString().slice(-1);

    if (["11", "12", "13", "14", "15", "16", "17", "18", "19"].includes(x)) {
        return "гостей";
    } else if (["1"].includes(y)) {
        return "гость";
    }
    else if (["2", "3", "4"].includes(y)) {
        return "гостя";
    } else {
        return "гостей";
    }
}

/**
 * Выбор окончания слова 'место' / Род. падеж
 * 
 * @param mixed count - кол-во мест
 * 
 * @return [type]
 */
export function localePlace(count) {
    // 2 последних символа
    const x = count.toString().slice(-2);
    // 1 последний символ
    const y = count.toString().slice(-1);

    if (["11", "12", "13", "14", "15", "16", "17", "18", "19"].includes(x)) {
        return "мест";
    } else if (["1"].includes(y)) {
        return "места";
    } else {
        return "мест";
    }
}

/**
 * Выбор окончания слова 'номер' / Род. падеж
 * 
 * @param mixed count - кол-во мест (до 10)
 * 
 * @return [type]
 */
export function localeRooms(count) {
    switch (count) {
        case 1: return "номер";
        case 2: return "номера";
        case 3: return "номера";
        case 4: return "номера";
        default: return "номеров";
    }
}