import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { StepperInput } from "../../UI/StepperInput";
import styles from "./style.module.css";
import { Select } from "../../UI/Select";
import { childrenOptions } from "./utils/childrenOptions";
import { Button } from "../../UI/Button/Button";
import { useEffect, useState } from "react";
import validateRoomsEvent from "./utils/validateRoomsEvent";

// Селект с возрастами ребенка
function ChildrenSelect({ id, room, value, state, setState }) {
    const [error, setError] = useState(null);

    useEffect(() => {
        function validateSelect() {
            if (!value) {
                setError("Укажите возраст ребенка");
            } else {
                setError(null);
            }
        }

        document.addEventListener(validateRoomsEvent.eventName, validateSelect);

        return () => {
            document.removeEventListener(validateRoomsEvent.eventName, validateSelect)
        }
    }, [value]);

    function changeHandler(value) {
        setError(null);
        setState(state.map((item, key) => {
            if (key === room) {
                return {
                    ...item,
                    childrens: item.childrens.map((i, k) => {
                        if (k === id) {
                            return value;
                        } else {
                            return i;
                        }
                    })
                }
            } else {
                return item;
            }
        }))
    }

    return (
        <div className={styles.group}>
            <div className={styles.label}>Возраст {id + 1} ребенка</div>
            <Select options={childrenOptions} value={value} onChange={changeHandler} error={error} />
        </div>
    )
}

// Номер
function Room({ id, room, state, setState, limits }) {
    function changeAdultsHandler(e) {
        const re = /^[0-9\b]+$/;
        let max = Math.min(limits.peoples - room.childrens.length, limits.adults);
        let min = 1;

        e.target.value = +e.target.value;

        if (e.target.value > max) {
            e.target.value = max;
        }

        if (e.target.value < min) {
            e.target.value = min;
        }

        if (e.target.value === "" || re.test(e.target.value)) {
            setState(state.map((item, key) => {
                if (key === id) {
                    return {
                        ...item,
                        adults: e.target.value === "" ? 0 : +e.target.value
                    }
                } else {
                    return item;
                }
            }));
        }
    }

    function changeChildrensHandler(e) {
        const re = /^[0-9\b]+$/;

        let childrens = room.childrens;

        let max = limits.peoples - room.adults;
        let min = 0;

        e.target.value = +e.target.value;

        if (e.target.value > max) {
            e.target.value = max;
        }

        if (e.target.value < min) {
            e.target.value = min;
        }

        if (e.target.value > childrens.length) {
            childrens = [
                ...childrens,
                ...[...new Array(e.target.value - childrens.length)].map(() => '')
            ]
        } else {
            childrens = childrens.filter((item, key) => key < e.target.value)
        }

        if (e.target.value === "" || re.test(e.target.value)) {
            setState(state.map((item, key) => {
                if (key === id) {
                    return {
                        ...item,
                        childrens: childrens
                    }
                } else {
                    return item;
                }
            }));
        }
    }

    function clickTrashHandler() {
        setState(state.filter((item, key) => key !== id));
    }

    return (
        <div className={styles.room}>
            <div className={styles.roomHead}>
                <div className={styles.subtitle}>Номер {id + 1}</div>
                {id !== 0 &&
                    <button type="button" className={styles.trash} onClick={clickTrashHandler}><TrashIcon /></button>
                }
            </div>
            <div className={styles.roomBody}>
                <div className={styles.inputs}>
                    <div className={styles.group}>
                        <div className={styles.label}>Взрослые</div>
                        <StepperInput value={room.adults} onChange={changeAdultsHandler} min={1} max={Math.min(limits.peoples - room.childrens.length, limits.adults)} />
                    </div>
                    <div className={styles.group}>
                        <div className={styles.label}>Дети</div>
                        <StepperInput value={room.childrens.length} onChange={changeChildrensHandler} min={0} max={limits.peoples - room.adults} />
                    </div>
                </div>
                <div className={styles.selects}>
                    {room.childrens.map((item, key) => <ChildrenSelect key={key} id={key} room={id} value={item} state={state} setState={setState} limits={limits} />)}
                </div>
            </div>
        </div>
    )
}

export function RoomsPicker({ state, setState, limits, setOpen, dateChangeCB = () => { } }) {
    // Добавление 1 номера
    function clickAddHandler() {
        setState([
            ...state,
            {
                adults: 2,
                childrens: []
            }
        ])
    }

    function validate() {
        document.dispatchEvent(validateRoomsEvent.event);


        // Если нет пустых селектов с детьми - закрываем
        if (state.filter(item => item.childrens.includes("")).length === 0) {
            setOpen(false);
            dateChangeCB();
        }
    }

    useEffect(() => {
        document.dispatchEvent(validateRoomsEvent.event);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Количество гостей</div>
            <div className={styles.rooms}>
                {state.map((room, key) => <Room key={key} id={key} room={room} state={state} setState={setState} limits={limits} />)}
            </div>
            <div className={styles.buttons}>
                {limits.rooms > state.length &&
                    <Button type="button" color="gray" onClick={clickAddHandler}>
                        <PlusIcon /> Добавить номер
                    </Button>
                }
                <Button onClick={validate}>Подтвердить</Button>
            </div>
        </div>
    )
}