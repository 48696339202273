.trigger {
    all: unset;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-radius: 4px;
    padding: 0 16px;
    font-size: 13px;
    line-height: 1;
    height: 35px;
    gap: 5px;
    background-color: white;
    color: var(--color-gray);
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
    -webkit-box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
    cursor: pointer;
}

.trigger:hover {
    background-color: var(--color-gray-light);
}

.trigger[data-placeholder] {
    color: var(--color-lilac);
}

.icon {
    color: var(--color-gray);
}

.content {
    overflow: hidden;
    background-color: white;
    border-radius: 6px;
    -webkit-appearance: none;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    -webkit-box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    width: var(--radix-select-trigger-width);
    /* max-height: var(--radix-select-content-available-height); */
}

.viewport {
    padding: 5px;
}

.item {
    font-size: 13px;
    line-height: 1;
    color: var(--color-lilac-dark);
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
}

.item[data-highlighted] {
    outline: none;
    background-color: var(--color-lilac-dark);
    color: var(--color-white);
}

.label {
    padding: 0 25px;
    font-size: 12px;
    line-height: 25px;
}

.separator {
    height: 1px;
    margin: 5px;
}

.itemIndicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.scrollButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    cursor: default;
}

.triggerError {
    border: 1px solid red;
}

.error {
    margin-top: 2px;
    font-size: 13px;
    color: red;
}