import React from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import classnames from 'classnames';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import styles from "./style.module.css";

export function Select({ options, value, onChange, error = "Укажите возраст ребенка" }) {
    return (
        <RadixSelect.Root value={value} onValueChange={onChange}>
            <RadixSelect.Trigger className={classnames(styles.trigger, error ? styles.triggerError : "")} aria-label="Food">
                <RadixSelect.Value placeholder="Выбрать" />
                <RadixSelect.Icon className={styles.icon}>
                    <ChevronDownIcon />
                </RadixSelect.Icon>
            </RadixSelect.Trigger>
            {error && <div className={styles.error}>{error}</div>}
            <RadixSelect.Portal>
                <RadixSelect.Content className={styles.content}>
                    <RadixSelect.ScrollUpButton className={styles.scrollButton}>
                        <ChevronUpIcon />
                    </RadixSelect.ScrollUpButton>
                    <RadixSelect.Viewport className={styles.viewport}>
                        <RadixSelect.Group>
                            {options.map((item, key) => <SelectItem key={key} value={item.value}>{item.text}</SelectItem>)}
                        </RadixSelect.Group>
                    </RadixSelect.Viewport>
                    <RadixSelect.ScrollDownButton className={styles.scrollButton}>
                        <ChevronDownIcon />
                    </RadixSelect.ScrollDownButton>
                </RadixSelect.Content>
            </RadixSelect.Portal>
        </RadixSelect.Root>
    );
}

const SelectItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
    return (
        <RadixSelect.Item className={classnames(styles.item, className)} {...props} ref={forwardedRef}>
            <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
            <RadixSelect.ItemIndicator className={styles.itemIndicator}>
                <CheckIcon />
            </RadixSelect.ItemIndicator>
        </RadixSelect.Item>
    );
});