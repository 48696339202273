.wrapper {
    display: inline-flex;
    gap: 12px;
    background: var(--color-white);
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
    min-width: 220px;
    width: auto;
}

.group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.icon {
    display: flex;
}

.icon svg {
    width: 16px;
    height: 16px;
    margin: auto;
}

.icon svg * {
    fill: var(--color-lilac-dark);
}

.label {
    font-size: 12px;
    color: var(--color-lilac-dark);
}

.input {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    color: var(--color-lilac-dark);
    white-space: nowrap;
    overflow: hidden;
}

@keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}

.loader {
    height: 19px;
    min-width: 154px;
    color: var(--color-lilac);
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
}

.loader svg {
    width: 19px;
    height: 19px;
    animation: 1.5s infinite linear spin;
}