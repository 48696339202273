import classNames from "classnames";
import styles from "./style.module.css";

export function WidjetInput({
    icon,
    label,
    inputProps,
    onMouseDown,
    id,
    className = ""
}) {
    return (
        <label className={classNames(styles.wrapper, className)} onMouseDown={onMouseDown} id={id}>
            {icon && <div className={styles.icon}>
                {icon}
            </div>}
            <div className={styles.group}>
                <div className={styles.label}>{label}</div>
                <div className={classNames(styles.input, inputProps.className)}>{inputProps.value}</div>
                {/* <input {...inputProps} className={classNames(styles.input, inputProps.className)} /> */}
            </div>
        </label>
    )
}