.wrapper {
    padding: 12px;
    border-radius: 4px;
    -webkit-appearance: none;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    -webkit-box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    background: var(--color-white);
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.rooms {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 480px;
    overflow: auto;
    padding-right: 4px;
}

.rooms::-webkit-scrollbar {
    width: 4px;
    padding-left: 4px;
}

.rooms::-webkit-scrollbar-track {
    background: var(--color-lilac);
}

.rooms::-webkit-scrollbar-thumb {
    background: var(--color-lilac-dark);
}

.title {
    font-size: 18px;
    font-weight: 500;
}

.room,
.add {
    --s: 16px;
    /* the size on the corner */
    --t: 1px;
    /* the thickness of the border */
    --g: 16px;
    /* the gap between the border and image */

    padding: calc(var(--g) + var(--t));
    outline: var(--t) solid var(--color-gray);
    /* the color here */
    outline-offset: calc(-1*var(--t));
    -webkit-mask:
        conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box;
}

.room {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.roomHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.roomBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.inputs {
    display: flex;
    gap: 16px;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
}


.label {
    font-size: 13px;
    margin-bottom: 2px;
    color: var(--color-lilac-dark);
}

.add {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    gap: 8px;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    opacity: .5;
    transition: .2s ease;
}

.add svg {
    width: 20px;
    height: 20px;
}

.add:hover {
    opacity: 1;
}

.trash {
    width: 20px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.trash svg {
    width: 20px;
    height: 20px;
}

.group {
    width: 100%;
}

.selects {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.buttons {
    display: flex;
}