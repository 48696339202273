import { createStore } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { getCalendarPrices } from "./api/getCalendarPrices";
import { getBookingSettings } from "./api/getBookingSettings";
import { getRoomsLimits } from "./api/getRoomsLimits";

export const useWidjetStore = (
    dateFrom = null,
    dateTo = null,
    rooms = [
        {
            adults: 1,
            childrens: []
        },
    ],
    timeSettings = null,
) => {

    const defaultProps = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        rooms: rooms,
        roomsLimits: {
            rooms: 1,
            adults: 1,
            peoples: 1,
        },
        selectedRooms: [],
        bookingReservationData: {},
        bookingSettings: null,
        calendarPrices: null,
        timeSettings: timeSettings,
        isFailed: false
    }
    return createStore(
        persist(
            (set, get) => ({
                ...defaultProps,
                // TODO: Подгружать roomsLimits с API
                setDateFrom: (dateFrom) => set(() => ({ dateFrom: dateFrom })),
                setDateTo: (dateTo) => set(() => ({ dateTo: dateTo })),
                setRooms: (rooms) => set(() => ({ rooms: rooms })),
                updateSelectedRooms: rooms => set(() => ({ selectedRooms: rooms })),
                resetBookingInfo: () => set(() => ({ selectedRooms: [] })),
                setBookingReservationData: data => set(() => ({ bookingReservationData: data })),
                resetBookingReservationData: () => set(() => ({
                    bookingReservationData: {
                        contacts: {
                            name: "",
                            email: "",
                            phone: ""
                        },
                    }
                })),
                setTimeSettings: data => set(() => ({ timeSettings: data })),
                setIsFailed: bool => set(() => ({ isFailed: bool })),
                updateRoomsLimits: async () => {
                    try {
                        const response = await getRoomsLimits();
                        const newLimits = {
                            rooms: response.rooms,
                            adults: response.adults,
                            peoples: response.peoples,
                        }

                        set(state => ({
                            roomsLimits: newLimits,
                            rooms: JSON.stringify(state.roomsLimits) === JSON.stringify(newLimits) ? state.rooms : defaultProps.rooms
                        }))
                    } catch (e) {
                        get().setIsFailed(true);
                    }
                },
                updateCalendarPrices: async () => {
                    try {
                        const response = await getCalendarPrices();
                        set(state => ({ calendarPrices: response }))
                    } catch (e) {
                        get().setIsFailed(true);
                    }
                },
                updateBookingSettings: async () => {
                    try {
                        const response = await getBookingSettings();
                        set(state => ({ bookingSettings: response }))
                    } catch (e) {
                        get().setIsFailed(true);
                    }
                },
            }),
            {
                name: "lv-widjet-storage",
                partialize: state => ({ dateFrom: state.dateFrom, dateTo: state.dateTo, rooms: state.rooms, roomsLimits: state.roomsLimits }),
                storage: createJSONStorage(
                    () => sessionStorage,
                    {
                        // Восстанавливаем объекты Date из хранилища
                        reviver: (_key, value) => {
                            if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
                                return new Date(value);
                            }
                            return value;
                        }
                    }
                ),
                version: 1
            }
        )
    );
};