import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import React, { useRef } from "react";
import styles from "./style.module.css";
import classNames from "classnames";

export const StepperInput = ({
    label,
    id,
    name,
    value,
    onChange,
    min = 0,
    max,
    className,
    disabled,
    readOnly
}) => {
    const EVENT_TYPES = { increase: 'INCREASE', decrease: 'DECREASE' };
    const { increase, decrease } = EVENT_TYPES;
    const inputRef = useRef(null);

    const handleInputValue = type => {
        const customEvent = new Event("change", { bubbles: true });

        if (type === increase) {
            inputRef.current.stepUp();
        }
        if (type === decrease) {
            inputRef.current.stepDown();
        }

        inputRef.current.dispatchEvent(customEvent);
    };

    return (
        <div className={styles.wrapper}>
            <button type="button" onClick={() => handleInputValue(decrease)} className={`${styles.button} ${styles.minus} ${value <= min ? styles.disabled : ''}`}>
                <MinusIcon />
            </button>
            <input
                id={id}
                name={name}
                type="number"
                value={value}
                min={min}
                max={max}
                onChange={onChange}
                ref={inputRef}
                readOnly={readOnly}
                disabled={disabled}
                pattern="[0-9]*"
                inputMode="numeric"
                className={classNames(styles.stepper, className)}
            />
            <button type="button" onClick={() => handleInputValue(increase)} className={`${styles.button} ${styles.plus} ${value >= max ? styles.disabled : ''}`}>
                <PlusIcon />
            </button>
        </div>
    );
};